import PropTypes from 'prop-types'
import { useContext } from 'react'
import { setVariantString } from '@monobits/theme-core'
import { Text, ColorSwitch } from '@monobits/components'
import { useModes } from '@monobits/color-transitions'

import { TimeContext } from './TimeProvider'

const KEYS = {
  VARIANT: 'timeStamp',
  TYPE: 'colorswitch.types',
}

/**
 * This is the TimeStamp component
 */
const TimeStamp = ({ children, variant, type, sx, ...props }) => {
  const { date, whitelist } = useContext(TimeContext)
  const context = useModes()

  return (
    <div sx={{ variant: setVariantString(KEYS.VARIANT, variant), ...sx }} {...props}>
      <ColorSwitch context={context} sx={{ variant: setVariantString(KEYS.TYPE, type) }} whitelist={whitelist} />
      <Text as="span">{date}</Text>
    </div>
  )
}

TimeStamp.propTypes = {
  /** Takes the iso code of the current locale */
  iso: PropTypes.string,
  /** The refresh interval in ms */
  interval: PropTypes.number,
  /** Date formatting options */
  options: PropTypes.shape({
    format: PropTypes.string,
    locale: PropTypes.string,
    replaceToH: PropTypes.string,
  }),
  /** Array containing whitelisted color mode keys */
  whitelist: PropTypes.arrayOf(PropTypes.string),
}

export default TimeStamp
