import { Box } from 'theme-ui'

import Field from './Field'
import HiddenField from './HiddenField'
import Select from './Select'

const KEYS = { VARIANT: 'forms' }

/**
 * This is the Form component
 */
const Form = ({ children, sx, ...props }) => (
  <Box sx={{ variant: KEYS.VARIANT, ...sx }} {...props}>
    {children}
  </Box>
)

Form.Field = Field
Form.HiddenField = HiddenField
Form.Select = Select

export default Form
