const responsive = (value) => ['1/-1', null, value]

export default {
  styles: {
    position: 'relative',

    '--columns': ['6', null, '12'],

    display: 'grid',
    gridTemplateColumns: 'repeat(var(--columns), 1fr)',
    gridRowGap: 'var(--padding)',
    gridColumnGap: 1,
  },
  content: {
    variant: 'blocks.styles',

    caption: { gridColumn: responsive('1/3') },
    rte: { gridColumn: responsive('5/-1') },
    form: { gridColumn: responsive('5/-1') },

    cta: {
      position: 'absolute',
      gridColumn: responsive('3/4'),
      zIndex: 'nav',

      justifySelf: ['end', null, 'start'],

      '@media (max-width: 61.95em)': {
        transform: 'none!important',
      },

      '> a, > button': {
        transform: 'translateY(-55%)',
      },
    },
  },
  contact: {
    variant: 'blocks.styles',

    caption: { gridColumn: responsive('1/3') },
    rte: {
      '[data-with-qr=true] &': { '--column-span': '5/10' },
      '[data-with-qr=false] &': { '--column-span': '5/-1' },
      gridColumn: responsive('var(--column-span)'),
    },
    qr: {
      gridColumn: responsive('11/13'),
      gridRow: '1/2',

      width: '75%',
      display: 'flex',
      alignItems: 'flex-start',
      justifySelf: 'end',

      svg: { width: '100%', height: 'auto' },
    },
  },
}
