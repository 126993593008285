import { Section, Text, Underlined } from '@monobits/components'
import { Form as Base } from '@boiler/components'
import SbEditable from 'storyblok-react'
import { NetlifyForm, Honeypot } from 'react-netlify-forms'
import { omit } from 'lodash'

import { Bloks } from '../bloks'
import Rte from './Rte'

import ArrowDown from '../../assets/svg/arrow-down.svg'
import Upload from '../../assets/svg/upload.svg'

const skip = [
  'lang',
  'settings',
  'locales',
  'realPath',
  'location',
  'path',
  'uri',
  'params',
  'pageResources',
  'navigate',
]

const Form = ({ children, sx, blok, lang, ...props }) => {
  const { caption, body, rte, submit, name, action = '/thanks', _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Section
        variant="border"
        sx={{ variant: 'blocks.content', ...sx }}
        data-handle={_uid}
        data-scroll
        data-fade
        {...props}
      >
        <Text variant="caption" sx={{ variant: 'blocks.content.caption' }}>
          {caption}
        </Text>
        <NetlifyForm name={name || _uid} action={action} honeypotName="bot-field">
          {({ handleChange: watch, success, error }) => (
            <Base data-form-state={(success && 'success') || (error && 'error') || null}>
              <Honeypot />

              <article className="overlay">
                {(success || error) && <Rte content={(success && blok?.success) || blok?.error} />}
              </article>

              <Rte content={rte} sx={{ mb: 1 }} />

              {body?.length
                ? body.map((child) => <Bloks blok={child} key={child._uid} handler={watch} {...props} />)
                : null}

              <Underlined
                as="button"
                type="submit"
                sx={{ variant: 'text.text', textTransform: 'inherit', '--gap': '-0.5em' }}
              >
                {submit}
              </Underlined>
            </Base>
          )}
        </NetlifyForm>
      </Section>
    </SbEditable>
  )
}

export const Field = ({ blok, sx, lang, ...props }) => {
  const { handle, name, required, type, component, _uid } = blok ?? {}

  const getOption =
    {
      input: { type },
      textarea: { type: 'textarea' },
    }[component] || type

  const getIcon = { file: Upload }[type] || null

  return (
    <SbEditable content={blok} key={_uid}>
      <Base.Field
        icon={getIcon}
        name={name}
        handle={handle}
        type={getOption.type}
        required={required}
        {...omit(props, skip)}
      />
    </SbEditable>
  )
}

export const FieldGroup = ({ blok, sx, lang, ...props }) => {
  const { items } = blok ?? {}
  const content = items?.length ? items.map((child) => <Bloks blok={child} key={child._uid} {...props} />) : null

  return (
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
        gridColumnGap: 1,
        gridRowGap: '3.5em',
        ...sx,
      }}
    >
      {content}
    </div>
  )
}

export const Select = ({ blok, sx, lang, ...props }) => {
  const { handle, name, required, _uid } = blok ?? {}
  const { list } = blok.options ?? {}

  const options = list.map(({ text }) => text)

  return (
    <SbEditable content={blok} key={_uid}>
      <Base.Select
        name={name}
        handle={handle}
        options={options}
        required={required}
        uuid={_uid}
        icon={ArrowDown}
        {...omit(props, skip)}
      />
    </SbEditable>
  )
}

export default Form
