import { kebabCase, startCase } from 'lodash'

export const handleize = kebabCase
export const titleCase = startCase

export const localize = (handle, locale = {}, fallback) => {
  const key = kebabCase(handle ?? '')
  return locale?.[key] ?? fallback ?? titleCase(key)
}

/**
 * Utility following Storyblok data structure change.
 *
 * @param {*} lang
 * @example
 *  const i18n = makeFallbacki18n(lang ?? {})
 *  const content = i18n.string('content', node?.content ?? {})
 *
 * @example
 *  const i18n = makeFallbacki18n(lang ?? {})
 *  const { content, includes } = i18n.array(['content', 'includes'], node?.content ?? {})
 */
export const makeFallbacki18n = (lang) => {
  const LOCALIZED = (lang?.internal ?? 'default') !== 'default'
  const string = (key = '', obj = {}) =>
    !!LOCALIZED ? obj?.[`${key}__i18n__${lang?.internal}`] ?? obj?.[key] : obj?.[key]
  const array = (keys = [], obj = {}) => keys?.reduce((acc, prop) => ({ ...acc, [prop]: string(prop, obj) }), {})

  return { string, array }
}
