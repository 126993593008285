import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { Text } from '@monobits/components'
import { StaffItem, StaffCard } from '@boiler/components'
import { localize } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import CloudImage from './Cloudinary/CloudImage'
import Bio from './Bio'
import Plus from '../../assets/svg/plus.svg'

const options = {
  preloadAmount: 6,
  staggerDelay: 0.35,
}

const StaffList = ({ blok, locales: { locales, members }, settings, lang, ...props }) => {
  const { title: main, type, items, _uid } = blok ?? {}
  const variant = { list: 'content', cards: 'cards' }[type] || null

  return (
    items.length && (
      <SbEditable content={blok} key={_uid}>
        <motion.section
          sx={{ variant: `layout.section.${variant}`, position: 'unset' }}
          data-handle={_uid}
          aria-label={main}
          // {...props}
        >
          {items.map((item, i) => {
            const {
              title,
              master,
              job_title,
              year_of_admission,
              languages,
              email,
              phone,
              phone_id,
              fax,
              images: [main],
            } = item.content ?? {}

            const to = '/' + item.full_slug

            if (type === 'cards') {
              const preload = i < options.preloadAmount
              const lazyload = preload ? false : null

              return (
                <StaffCard
                  key={item.uuid}
                  icon={Plus}
                  link={{ as: Link, to }}
                  image={[
                    CloudImage,
                    { url: main.image.filename, alt: main.image?.alt, options: { lazyload, placeholder: false } },
                  ]}
                  head={[<Bio.Name master={master} name={title} locales={locales} />, localize(job_title, members)].map(
                    (content, i) => (
                      <Text variant="caption" rte={false} key={'heads' + item.uuid + i}>
                        {content}
                      </Text>
                    )
                  )}
                  sx={{
                    transitionDelay: 'var(--inview-delay)',
                    '@media (min-width: 1355px)': { transitionDelay: `${(i % 3) * options.staggerDelay}s` },
                  }}
                >
                  {[
                    <Bio.Phone phone={{ number: phone, ext: phone_id }} locales={locales} />,
                    <Bio.Fax fax={fax} locales={locales} />,
                    <Bio.Email email={email} title={settings?.meta_title} />,
                    year_of_admission && <Bio.Admission year={year_of_admission} locales={locales} />,
                    !!languages.length && <Bio.Languages languages={languages} locales={{ ...locales, ...members }} />,
                  ]
                    .filter(Boolean)
                    .map((content, i) => (
                      <Text as="li" variant="caption" rte={false} key={'content' + item.uuid + i}>
                        {content}
                      </Text>
                    ))}
                </StaffCard>
              )
            }

            return (
              <StaffItem
                as={Link}
                to={to}
                key={item.uuid}
                image={<CloudImage url={main.image.filename} alt={main.image?.alt} options={{ placeholder: false }} />}
                children={<Bio.Name master={master} name={title} locales={locales} />}
                data-scroll
                data-fade
              />
            )
          })}
        </motion.section>
      </SbEditable>
    )
  )
}

export default StaffList
