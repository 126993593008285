import { transitions } from 'polished'
import { inset } from '@boiler/utils'

export default {
  __default: {
    gridGap: 1,
    '--speed': '1s',
    '--ease': 'ease',

    ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease)'),
    willChange: 'auto',

    '&[data-loaded]': {
      opacity: 0,
      transform: [null, null, 'translateY(var(--padding-m))'],
    },
    '&[data-loaded=true]._inview': {
      opacity: 1,
      transform: 'none',
    },
  },
  content: {
    gridTemplateColumns: '1fr 1fr auto',
    gridGap: 1,

    gridTemplateAreas: '"head head head" "body body icon"',
    alignItems: 'end',
  },
  image: {
    overflow: 'hidden',
    position: 'relative',
    '--aspect-ratio': '4/5',

    '::after': {
      content: '""',
      pb: 'calc(100% / (var(--aspect-ratio)))',
      display: 'flex',
    },

    '.image': {
      position: 'absolute',
      ...inset(0),

      img: {
        objectPosition: 'top center',
      },

      transform: 'scale(1)',
      transition: 'transform var(--speed) var(--ease)',
    },

    '@media (hover: hover)': {
      '[data-hover]:hover & > div': {
        transform: 'scale(1.03)',
      },
    },
  },
  head: {
    gridArea: 'head',
    justifyContent: 'space-between',
    gap: 1,
  },
  body: {
    gridArea: 'body',
    gridGap: 'var(--nav-gap)',
  },
  icon: {
    gridArea: 'icon',
    size: 'icon',

    borderRadius: '50%',
    border: '1px solid currentColor',

    svg: {
      transition: 'transform var(--speed) var(--ease)',
    },

    '@media (hover: hover)': {
      '[data-hover]:hover & svg': {
        transform: 'rotate(90deg)',
      },
    },
  },
}
