import { Section, Text } from '@monobits/components'
import SbEditable from 'storyblok-react'

import QR from './QR'
import Rte from './Rte'
import { Bloks } from '../bloks'

const ContactBlock = ({ blok, children, lang, ...props }) => {
  const { caption, body, qr, qr_text, _uid } = blok ?? {}

  const content = body?.length
    ? body.map((child) => (
        <Bloks
          blok={child}
          key={child._uid}
          sx={{ fontSize: 'inherit', textTransform: 'inherit' }}
          inverted={false}
          {...props}
        />
      ))
    : null

  return (
    <SbEditable content={blok} key={_uid}>
      <Section
        variant="border"
        sx={{ variant: 'blocks.contact' }}
        data-with-qr={!!qr}
        data-handle={_uid}
        data-scroll
        data-fade
        {...props}
      >
        <span className="anchor" data-anchor={_uid} />
        <Text variant="caption" sx={{ variant: 'blocks.contact.caption' }}>
          {caption}
        </Text>

        {body?.length && <Rte sx={{ variant: 'blocks.contact.rte' }}>{content}</Rte>}
        <Rte content={qr_text} sx={{ variant: 'blocks.contact.rte' }} className="-show-desktop" />

        {qr && (
          <a
            href={qr}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ variant: 'blocks.contact.qr' }}
            className="-show-desktop"
          >
            <QR value={qr} renderAs="svg" bgColor="transparent" fgColor="currentColor" level="M" />
          </a>
        )}
      </Section>
    </SbEditable>
  )
}

export default ContactBlock
