import { createContext, useContext } from 'react'
import { Grid, Flex, useThemeUI } from 'theme-ui'
import { Icon, Heading, Section, Collapsible as Base } from '@monobits/components'
import { withHover } from '@monobits/core'
import { useSet } from '@react-hookz/web'
import SbEditable from 'storyblok-react'

import Rte from './Rte'
import Plus from '../../assets/svg/plus.svg'

const CollapseContext = createContext()

const Collapsible = withHover(
  ({ children, title, id, sx, hover, ...props }) => {
    const {
      transition: { duration },
      bezier,
    } = useThemeUI().theme?.motion.collapsible ?? {}
    const set = useContext(CollapseContext)

    const opened = !!set.has(id)

    return (
      <div
        sx={{
          variant: 'list.collapsible',
          '--speed': `${duration}s`,
          '--ease': bezier,
          ...sx,
        }}
        data-handle={id}
        data-selected={!!hover || opened}
        aria-expanded={opened}
        data-scroll
        data-fade
        {...props}
      >
        <Grid
          as="button"
          sx={{ variant: 'list.collapsible.head' }}
          onClick={() => (opened ? set.delete(id) : set.add(id))}
        >
          <Heading as="h3" variant="subtitle" sx={{ variant: 'list.collapsible.text' }}>
            {title}
          </Heading>
          <Flex sx={{ variant: 'list.icon' }}>
            <Flex as="aside">
              <Icon icon={Plus} sx={{ '--stroke-absolute': 'var(--stroke-absolute-value)' }} />
            </Flex>
          </Flex>
        </Grid>
        <Base onTrigger={!opened}>{children}</Base>
      </div>
    )
  },
  { mouse: true }
)

const Collapsibles = ({ blok, children, ...props }) => {
  const { items, _uid } = blok ?? {}
  const set = useSet([])

  return (
    <SbEditable content={blok} key={_uid}>
      <CollapseContext.Provider value={set}>
        <Section variant="content" {...props}>
          {items.map((item, i) => (
            <SbEditable content={item} key={item._uid}>
              <Collapsible title={item.title} id={item._uid} key={item._uid}>
                <Rte content={item.rte} sx={{ textTransform: 'initial', mt: 1, mb: 2 }} />
              </Collapsible>
            </SbEditable>
          ))}
        </Section>
      </CollapseContext.Provider>
    </SbEditable>
  )
}

export default Collapsibles
