import { snakeCaseReducer } from '../'

export const getStory = async ({ Storyblok, event, config, setter, ISO }) => {
  // Story
  await Storyblok.get(`cdn/stories/${event.storyId}`, {
    version: 'draft',
    language: ISO === 'default' ? null : ISO,
    ...snakeCaseReducer(config),
  }).then(({ data }) => data.story && setter(data.story))
}
