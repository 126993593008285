import { inset } from '@boiler/utils'

export default {
  __default: {
    size: '100%',
    overflow: 'hidden',
    placeSelf: 'center',
    pt: 1,
  },
  container: {
    position: 'relative',
    size: '100%',

    '.image': {
      position: 'absolute',
      ...inset(0),

      img: {
        objectPosition: 'center',
        objectFit: 'contain',
      },
    },

    '[data-hidden]': {
      display: 'none!important',
    },
  },
  nav: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 'nav',
    variant: 'text.caption',

    number: {
      minWidth: '2.5ch',
    },
  },
}
