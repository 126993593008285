import { useSetRecoilState } from 'recoil'
import { motion } from 'framer-motion'
import { Header as Molecule } from '@boiler/molecules'
import { Text } from '@monobits/components'
import { TimeStamp } from '@boiler/components'

import { useData } from '../hooks'
import { menuOpenState } from '../atoms'

import LogoLong from '../../assets/svg/logo-long.svg'
import LogoShort from '../../assets/svg/logo-short.svg'

const MotionHeader = motion(Molecule)

const Header = (props) => {
  const { locales } = useData()?.locales
  const setOpen = useSetRecoilState(menuOpenState)

  if (!locales) return null

  return (
    <MotionHeader nav={{ id: 'header' }} {...props}>
      <TimeStamp id="timestamp" />
      <Text as="button" aria-label="menu" onClick={() => setOpen(true)}>
        {locales['menu']}
      </Text>
    </MotionHeader>
  )
}

const LogoComponent = (props) => {
  const { lang } = useData()
  if (!lang) return null

  return (
    <Molecule.Logo
      logo={LogoLong}
      logoMobile={LogoShort}
      homePath={lang.nullish ? '/' + lang.nullish : '/'}
      {...props}
    />
  )
}

Header.Logo = LogoComponent

export default Header
