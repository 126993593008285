import { Icon } from '@monobits/components'
import ScrollTo from './ScrollTo'
import ArrowUp from '../../assets/svg/arrow-up-circle.svg'

const BackToTop = ({ sx, ...props }) => (
  <ScrollTo top sx={{ size: 'icon', p: '1px', ...sx }} {...props}>
    <Icon icon={ArrowUp} sx={{ '--stroke-absolute': 'var(--stroke-absolute-value)' }} />
  </ScrollTo>
)

export default BackToTop
