import { memo } from 'react'
import { Flex } from 'theme-ui'

import { Cloudinary } from '@cloudinary/base'
import { AdvancedImage } from '@cloudinary/react'
import cls from 'classnames'

import { inset } from '@boiler/utils'

import { getMedia, getCloudinaryProps, plugins } from './utils'
import * as tr from './transformations'

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
    url: { secure: !!(process.env.NODE_ENV === 'production') },
  },
})

/**
 * https://cloudinary.com/documentation/sdks/js/frontend-frameworks/index.html
 * @example <CloudImage sx={{ width: 300 }} ratio="contain" fit="cover" url={image?.filename} medias={medias} />
 */
const CloudImage = ({ url, medias = {}, ratio, fit = 'cover', options, modifiers, sx, className, ...props }) => {
  let cloudProps = getCloudinaryProps(url)
  let media = getMedia(medias, cloudProps.filename)

  let source = cld.image(cloudProps.filename)

  if (modifiers) {
    modifiers(source, tr)
  } else {
    source.addTransformation(tr.optimize()).addTransformation(tr.optimizeScale())
  }

  const image = <AdvancedImage cldImg={source} plugins={plugins({ ...options })} alt={media?.context?.custom?.alt} />

  return 'width' in media || ratio ? (
    <Flex
      {...props}
      className={cls(className, 'image')}
      data-aspect-ratio
      sx={{
        '--aspect-ratio': ratio ? ratio : `${media.width}/${media.height}`,
        position: 'relative',

        img: {
          size: '100%',
          objectFit: fit,
          position: 'absolute',
          ...inset(0),
        },
        sx,
      }}
    >
      {image}
    </Flex>
  ) : (
    <Flex className={cls(className, 'image')} sx={sx} {...props}>
      {image}
    </Flex>
  )
}

export default memo(CloudImage)
