import { inset } from '@boiler/utils'

export default {
  __default: {
    variant: 'list',

    justifyContent: 'center',
    textAlign: 'center',

    '> .text': {
      position: 'relative',

      '.stroke': {
        bg: 'currentColor',
        height: '0.08em',
        width: '100%',

        display: 'inline-block',
        pointerEvents: 'none',

        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,

        transform: 'scaleX(0)',
        transformOrigin: 'left',
        willChange: 'transform',
      },
    },

    '@media (hover: hover)': {
      '&:hover > .text': {
        textDecorationColor: 'text',
      },
    },

    position: 'relative',
  },
  hitbox: {
    position: 'absolute',
    ...inset(['-0.65em', '-1em', '-0.5em', '-1em']),
  },
  preview: {
    width: 'peek',

    '::after': {
      content: '""',
      pb: 'var(--aspect-ratio)',
      display: 'flex',
    },

    willChange: 'transform',
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: '10',
    overflow: 'hidden',

    '> .box': {
      position: 'absolute',
      ...inset(0),

      opacity: '0',
      visibility: 'hidden',
      transform: 'scale(0.85)',
    },

    '.image': {
      width: '100%',
      pointerEvents: 'none',
      position: 'absolute',
      ...inset(0),

      img: {
        objectPosition: 'top center',
      },
    },
  },
}
