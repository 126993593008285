import { useRef, createContext, memo } from 'react'
import { RecoilRoot } from 'recoil'
import { useThemeUI, Box } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion'
import { useSessionStorageValue } from '@react-hookz/web'
import { getWindow } from 'ssr-window'
import { LocomotiveScrollProvider } from '@monobits/locomotive-scroll'
import { ColorModeTransition } from '@monobits/color-transitions'
import { Sizes } from '@monobits/components'
import { TimeProvider } from '@boiler/components'

import { useStoryblok } from '../hooks'

import ScrollBridge from '../components/ScrollBridge'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

const window = getWindow()
const variantKeys = { initial: 'initial', animate: 'enter', exit: 'exit' }

export const StoryblokContext = createContext()

const Layout = ({ children, pageContext, location }) => {
  const ref = useRef(null)

  const { settings, locales = {} } = useStoryblok(pageContext, location)
  const { lang, slug, story } = pageContext ?? {}
  const {
    config: { locomotive },
    transitions: move,
  } = useThemeUI().theme ?? {}

  const [rendered, setRendered, removeRendered] = useSessionStorageValue('app-rendered', false, {
    handleStorageEvent: true,
    isolated: false,
  })

  window.onunload = () => removeRendered()

  if (slug === '/404/') return children

  const home = story?.slug === 'home'
  const member = story?.field_component === 'member'

  return (
    <RecoilRoot>
      <StoryblokContext.Provider value={{ settings, locales, lang, rendered: [rendered, setRendered] }}>
        <TimeProvider iso={lang?.iso ?? 'fr'} whitelist={settings?.color_switcher}>
          <ColorModeTransition />
          <Sizes callbacks={location} />

          <LocomotiveScrollProvider containerRef={ref} location={location} options={locomotive}>
            <ScrollBridge />
            <Menu
              location={location}
              motion={{
                key: lang?.iso || 'lang',
                variants: move.localized,
                ...variantKeys,
              }}
            />

            <Header variants={move.header} initial="hidden" animate={member ? 'hidden' : 'visible'} />

            <Box as="div" ref={ref} data-scroll-container>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={'logo' + location.pathname}
                  variants={move.page}
                  {...variantKeys}
                  animate={member || home ? 'hidden' : 'enter'}
                >
                  <Header.Logo />
                </motion.div>
                <motion.main data-inview="true" key={location.pathname} variants={move.page} {...variantKeys}>
                  {children}
                  {!member && <Footer />}
                </motion.main>
              </AnimatePresence>
            </Box>
          </LocomotiveScrollProvider>
        </TimeProvider>
      </StoryblokContext.Provider>
    </RecoilRoot>
  )
}

export default memo(Layout)
