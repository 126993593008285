import styled from '@emotion/styled'
import { css, Box } from 'theme-ui'

export const x = {
  opacity: 'var(--opacity, 1)',
}

export const StyledMenu = styled(Box)(
  css({
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 'panel',
  })
)
export const StyledContainer = styled(Box)(
  css({
    display: 'grid',
    boxSizing: 'var(--panel-sizing)',

    gridTemplateColumns: 'var(--template-columns)',
    gridTemplateRows: 'var(--template-rows)',
  })
)
