import { transitions } from 'polished'

const mediaClasses = (display, { prepend = true } = {}) => {
  const pre = !prepend ? '' : '-' + display
  return {
    ['.-hide-mobile' + pre]: { display: ['none', display] },
    ['.-hide-tablet' + pre]: { display: [null, 'none', display] },
    ['.-hide-desktop' + pre]: { display: [null, null, 'none'] },
    ['.-show-mobile' + pre]: { display: [null, 'none'] },
    ['.-show-tablet' + pre]: { display: ['none', display, 'none'] },
    ['.-show-desktop' + pre]: { display: ['none', null, display] },
  }
}

export default {
  ...mediaClasses('grid'),
  ...mediaClasses('block'),
  ...mediaClasses('flex', { prepend: false }),

  '[data-inview]:not([data-inview="false"])': {
    '[data-fade]': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease) var(--inview-delay)'),

      '&:not(._inview)': {
        opacity: 0,
        transform: 'translateY(var(--padding-m))',
      },
    },
  },

  '.-aspect-ratio, [data-aspect-ratio]': {
    '@supports (aspect-ratio: 1 / 1)': {
      aspectRatio: 'var(--aspect-ratio)',
    },
    '@supports not (aspect-ratio: 1 / 1)': {
      '::after': {
        content: '""',
        pb: 'calc(100% / (var(--aspect-ratio)))',
        display: 'flex',
      },
    },
  },
}
