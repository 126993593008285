const common = {
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: 'text',

  pt: 1,
  gridGap: 1,
  justifyItems: ['center', null, 'unset'],

  '@media (min-width: 62em)': {
    '>div:nth-of-type(1)': { textAlign: 'left', justifyContent: 'start' },
    '>div:nth-of-type(2)': { textAlign: 'center', justifyContent: 'center' },
    '>div:nth-of-type(3)': {
      textAlign: 'right',
      justifyContent: 'end',
      '.-inverted': { justifySelf: 'end' },
    },
  },
  '@media screen and (max-width: 61.95em)': {
    '>div': { textAlign: 'center', justifyItems: 'center' },
  },

  '>div': {
    '&.-desktop-only': { display: ['none', null, 'grid'] },
    '&:not(.-desktop-only)': { display: 'grid' },

    gridGap: ['0.6em', null, '0.5em'],
    height: 'fit-content',
  },
}

export default {
  container: {
    variant: 'layout.section.vh',
    '--offset': 'calc(var(--header-height) + var(--padding-x) + 10px)',

    pb: 1,
    px: 1,

    gridTemplateRows: 'auto auto 1fr auto',
    gridGap: 1,

    gridTemplateAreas: [
      "'logo' 'head' 'head' 'foot'",
      null,
      "'logo' 'head' '...' 'foot'",
      //
    ],

    svg: { gridArea: 'logo' },
  },
  head: {
    gridArea: 'head',
    gridTemplateColumns: ['1fr', null, '1fr 2fr 1fr'],
    ...common,

    '@media (max-width: 61.95em)': {
      gridGap: 1,
      placeItems: 'center',
      '[data-name=navigation]': {
        gridGap: '1.65em',
        '.stroke': { display: 'none' },
        '> a': { fontSize: 5 },
      },
    },
  },
  foot: {
    gridArea: 'foot',
    gridTemplateColumns: ['1fr', null, 'repeat(3, 1fr)'],
    ...common,
  },
}
