import SbEditable from 'storyblok-react'
import { useRecoilState } from 'recoil'
import { useLockBodyScroll } from 'react-use'
import { useUpdateEffect, useConditionalEffect, useClickOutside } from '@react-hookz/web'
import { useTimeline } from '@monobits/gsap'
import { Text } from '@monobits/components'

import { TimeStamp } from '@boiler/components'
import { Menu as Molecule } from '@boiler/molecules'
import { wrapChange } from '@boiler/utils'
import { useData } from '../hooks'
import { MapBloks } from '../bloks'
import { menuOpenState } from '../atoms'

import InvertedMode from './InvertedMode'
import LanguageSwitcher from './LanguageSwitcher'
import LogoLong from '../../assets/svg/logo-long.svg'
import LogoShort from '../../assets/svg/logo-short.svg'

const Menu = ({ location, ...props }) => {
  const {
    lang,
    settings,
    locales: { locales },
  } = useData() ?? null

  const [open, setOpen] = useRecoilState(menuOpenState)

  const [timeline, element] = useTimeline(({ gsap, $, node }) =>
    gsap
      .timeline({
        paused: true,
        defaults: {
          duration: 1.25,
          ease: 'expo.inOut',
        },
        ...wrapChange(node),
      })
      .set(node, { visibility: 'visible' })
      .to(node, { '--clip-path': '0%' })
  )

  useUpdateEffect(() => setOpen(false), [location])
  useClickOutside(element, () => setOpen(false))
  useLockBodyScroll(open || false)

  useConditionalEffect(
    () => {
      if (open) timeline.timeScale(1).play()
      if (!open) timeline.timeScale(1.75).reverse()
    },
    [open],
    [timeline]
  )

  if (!settings) return null

  const { navigation, legals, copyrights } = settings ?? {}

  return (
    <SbEditable content={settings} key={settings._uid}>
      <Molecule
        as="section"
        ref={element.ref}
        aria-expanded={open}
        primary={<MapBloks items={navigation} />}
        secondary={[
          <MapBloks items={legals} />,
          <MapBloks items={copyrights} rte={false} sx={{ whiteSpace: 'nowrap' }} />,
        ]}
        corner={<LanguageSwitcher {...lang} />}
        logo={[LogoShort, LogoLong]}
        homePath={lang.nullish ? '/' + lang.nullish : '/'}
        head={
          <>
            <TimeStamp type="inverted" />
            <Text as="button" aria-label="menu" onClick={() => setOpen(false)}>
              {locales['fermer']} [ X ]
            </Text>
          </>
        }
        wrapper={[InvertedMode, { shouldTween: !!open, ssx: { display: 'flex' }, observe: false }]}
        {...props}
      />
    </SbEditable>
  )
}

export default Menu
