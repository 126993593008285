import { snakeCase } from 'lodash'

export const snakeCaseReducer = (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...{ [snakeCase(key)]: value },
    }),
    {}
  )

export const parseContent = (content) => (typeof content === 'string' ? JSON.parse(content) : content)

export const inset = (value) => {
  const styles = {
    number: {
      top: value,
      left: value,
      bottom: value,
      right: value,
    },
    string: {
      top: value,
      left: value,
      bottom: value,
      right: value,
    },
    array: {
      top: value[0],
      left: value[1],
      bottom: value[2],
      right: value[3],
    }[typeof value],
  }

  return {
    '@supports (inset: 0)': { inset: value },
    '@supports not (inset: 0)': styles,
  }
}
