import { useState, forwardRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const range = '150px'

const variants = {
  enter: (direction) => {
    return {
      opacity: 0,
      x: direction > 0 ? range : `-${range}`,
    }
  },
  center: {
    zIndex: 1,
    opacity: 1,
    x: 0,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      opacity: 0,
      x: direction < 0 ? range : `-${range}`,
    }
  },
}

const wrap = (min, max, v) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

/**
 * This is the Slider component
 */
const Slider = forwardRef(({ children, sx, images, uuid, rendered, ...props }, ref) => {
  const [[page, direction], setPage] = useState([0, 0])

  const imageIndex = wrap(0, images.length, page)

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])
  }

  const [Image, imgProps] = images?.[imageIndex].slide
  const [, nextProps] = images?.[(imageIndex + 1) % images.length].slide
  const [, nextNextProps] = images?.[(imageIndex + 2) % images.length].slide

  return (
    <div ref={ref} sx={{ variant: 'slider', ...sx }} {...props}>
      <div sx={{ variant: 'slider.container', opacity: !rendered ? 0 : null }} id="slides">
        {!!images.length && (
          <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
            <motion.div
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) paginate(1)
                else if (swipe > swipeConfidenceThreshold) paginate(-1)
              }}
              sx={{ variant: 'slider.container' }}
            >
              <Image {...imgProps} />
              <Image {...nextProps} data-hidden />
              <Image {...nextNextProps} data-hidden />
            </motion.div>
          </AnimatePresence>
        )}
      </div>

      <aside id="slidernav" sx={{ variant: 'slider.nav', opacity: !rendered ? 0 : null }}>
        {!!images.length && (
          <>
            <span sx={{ variant: 'slider.nav.number' }}>
              {imageIndex + 1 < 10 ? `0${imageIndex + 1}` : imageIndex + 1}
            </span>
            <span>/ {images?.length}</span>
          </>
        )}
      </aside>
    </div>
  )
})

export default Slider
