export default {
  useLocalStorage: true,
  useColorSchemeMediaQuery: true,

  initialColorModeName: 'light',

  useStickyHeader: true,
  useShyHeader: false,

  colorTransitions: {
    duration: 0.5,
    ease: 'power1.inOut',
  },

  locomotive: {
    smooth: true,
    multiplier: 1,
    lerp: 0.15,
    scrollFromAnywhere: false,
    tablet: { breakpoint: 992 },
    extends: {
      scrub: false,
      rootPercent: 4,
      scrollDuration: 800,
    },
  },
}
