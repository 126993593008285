export const getIso = (pathname = '/', localized = true) => {
  if (!localized) return 'default'

  const parts = pathname.split('/').filter((i) => i !== '')
  const iso = parts?.[0]?.length === 2 ? parts[0] : 'default'
  return iso
}

const useISO = (pageContext, location, key = 'nullish', localized) => {
  const pathIso = getIso(location?.pathname, localized)
  const iso = pathIso === 'default' ? null : pathIso
  const context = pageContext ?? {}

  if (!localized) return 'default'

  return 'lang' in context ? context.lang[key] : iso
}

export default useISO
