import { makeHighlight } from '@monobits/colors'

const opacity = 0.15

const colors = {
  light: '#F7F0DC',
  dark: '#16280D',
}

export default {
  text: colors.dark,
  background: colors.light,
  primary: colors.dark,
  highlight: makeHighlight(colors.dark, opacity),
  ...colors,
  modes: {
    dark: {
      text: colors.light,
      background: colors.dark,
      primary: colors.light,
      highlight: makeHighlight(colors.light, opacity),
    },
  },
}
