import { Link } from 'gatsby'
import { Grid, Flex } from 'theme-ui'
import { Icon } from '@monobits/components'

const Part = ({ children, entries = [], ...props }) => {
  const [name, classes] = entries
  return (
    <div data-name={name} className={classes} {...props}>
      {children}
    </div>
  )
}

/**
 * This is the Footer component
 */
const Footer = ({ children, head, foot, source, logo: Logo, homePath, variant, sx, ...props }) => {
  const setItem = (i, push = 0) => Object.entries(source)[i + push]
  const linked = homePath && { as: Link, to: homePath }

  return (
    <Grid as="footer" sx={{ variant: 'footer.container', ...sx }} {...props}>
      {Logo && (
        <Flex {...linked} aria-label="Logo">
          <Icon icon={Logo} />
        </Flex>
      )}

      <Grid as="nav" sx={{ variant: 'footer.head' }}>
        {head.map((item, i) => (
          <Part key={'head' + i} entries={setItem(i)} children={item} />
        ))}
      </Grid>
      <Grid as="nav" sx={{ variant: 'footer.foot' }}>
        {foot.map((item, i) => (
          <Part key={'head' + i} entries={setItem(i, head.length)} children={item} />
        ))}
      </Grid>

      {children}
    </Grid>
  )
}

export default Footer
