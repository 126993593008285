const nativeDuration = 500
const duration = nativeDuration / 1000

export default {
  defaults: {
    duration,
    nativeDuration,
  },
  page: {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration,
        delay: duration / 2,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      transition: { duration },
    },
  },
  header: {
    hidden: {
      opacity: 0,
      transitionEnd: { visibility: 'hidden' },
      transition: { duration },
    },
    visible: {
      opacity: 1,
      visibility: 'visible',
      transition: { duration, delay: duration },
    },
  },
  localized: {
    initial: false,
    enter: {
      opacity: 1,
      transition: { duration: duration * 2, delay: duration * 2 },
    },
    exit: {
      opacity: 1,
      transition: { duration: duration * 2, delay: 0 },
    },
  },
  cards: {
    parent: {
      show: { transition: { staggerChildren: 0.3, delayChildren: 0.5 } },
      hidden: { transition: { staggerChildren: 0.25, staggerDirection: -1 } },
    },
    item: {
      show: {
        y: 0,
        opacity: 1,
        willChange: 'auto',
        transition: {
          duration: 1.45,
          ease: [0.16, 1, 0.3, 1],
        },
      },
      hidden: { y: 100, opacity: 0 },
    },
  },
  bio: {
    image: {
      initial: {
        scale: 1.2,
        rotate: -6,
        opacity: 0,
        width: '100%',
      },
      animate: {
        scale: 1,
        rotate: 0,
        opacity: 1,
        transition: {
          delay: 0.35,
          duration: 2,
          ease: [0.16, 1, 0.3, 1],
        },
      },
    },
    content: {
      initial: {
        y: 'var(--padding-m)',
        opacity: 0,
        width: '100%',
      },
      animate: {
        y: 0,
        opacity: 1,
        transition: {
          delay: 0.7,
          duration: 2,
          ease: [0.16, 1, 0.3, 1],
        },
      },
    },
  },
}
