import { forwardRef } from 'react'
import { Link } from 'gatsby'
import { Box, Grid } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon } from '@monobits/components'
import { setVariantString } from '@monobits/theme-core'

import { StyledMenu, StyledContainer } from './Menu.styled'

const KEYS = { VARIANT: 'menu' }

const Logo = ({ icon, ...props }) => (
  <Box {...props}>
    <Icon icon={icon} sx={{ width: 'unset' }} />
  </Box>
)

const MotionContainer = motion(StyledContainer)

const Wrapper = ({ children, component: Component, ...props }) =>
  Component ? <Component {...props}>{children}</Component> : children

/**
 * This is the Menu component
 */
const Menu = forwardRef(
  (
    {
      children,
      head,
      primary,
      secondary,
      corner,
      logo = [],
      homePath,
      variant,
      motion: m,
      wrapper: [wrapper, wrapperProps],
      ...props
    },
    ref
  ) => {
    const [mobile, desktop] = logo

    const linked = homePath && { as: Link, to: homePath }

    return (
      <StyledMenu ref={ref} sx={{ variant: setVariantString(KEYS.VARIANT, variant) }} {...props}>
        <Wrapper component={wrapper} {...wrapperProps}>
          <AnimatePresence exitBeforeEnter>
            <MotionContainer {...m} sx={{ variant: 'menu.container' }}>
              {head && (
                <header sx={{ variant: 'menu.head' }}>
                  <Logo
                    {...linked}
                    icon={mobile}
                    className="-hide-tablet-block -hide-desktop-block"
                    aria-label="Logo"
                  />
                  <Logo
                    {...linked}
                    icon={desktop}
                    className="-hide-mobile-block -hide-desktop-block"
                    aria-label="Logo"
                  />
                  <div>{head}</div>
                </header>
              )}

              <Grid as="nav" sx={{ variant: 'menu.navigation' }}>
                {primary}
              </Grid>

              {(secondary || corner) && (
                <Grid as="nav" sx={{ variant: 'menu.secondary' }} data-cta={!!corner}>
                  {secondary && secondary.map((item, i) => <Grid key={'secondary' + i} children={item} />)}
                  {corner}
                </Grid>
              )}
            </MotionContainer>
          </AnimatePresence>
        </Wrapper>
      </StyledMenu>
    )
  }
)

export default Menu
