import { useEffect, useState, createContext } from 'react'

import formatDate from 'intl-dateformat'
import { replace } from 'lodash'

export const TimeContext = createContext()

const TimeProvider = ({ children, iso = 'en', interval = 30000, options = {}, whitelist }) => {
  const [date, setDate] = useState(new Date())

  const { format, locale, replaceToH } = {
    ...{ format: 'HH:mm MMM. DD', locale: `${iso}-CA`, replaceToH: ':' },
    ...options,
  }

  useEffect(() => {
    const _interval = setInterval(() => setDate(new Date()), interval)
    return () => clearInterval(_interval)
  }, [date, iso])

  const intl = formatDate(date, format, { locale: locale })
  const formatted = replaceToH ? replace(intl, replaceToH, 'H') : intl

  return <TimeContext.Provider value={{ rawDate: date, date: formatted, whitelist }}>{children}</TimeContext.Provider>
}

export default TimeProvider
