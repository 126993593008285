import { Fragment } from 'react'

const NewlineText = ({ text = '', id = '' }) => {
  const array = text.split('\n')

  return array.map((str, i) => (
    <Fragment key={id + i}>
      {str} {i < array.length - 1 ? <br /> : null}
    </Fragment>
  ))
}

export default NewlineText
