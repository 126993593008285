import { useRef } from 'react'
import { gsap } from 'gsap'
import { clamp } from 'lodash'
import { useMountEffect } from '@react-hookz/web'
import { useRefs } from '@monobits/gsap'
import { useMousePos } from '@boiler/utils'

const $ = (obj) => obj?.current

const x = {
  enter: { duration: 1, ease: 'expo.out' },
  exit: { duration: 0.65, ease: 'expo.out' },
}

/**
 * This is the ImageTrail component
 */
const ImageTrail = ({ children, sx, images, range = [85, 115], ready, ...props }) => {
  let ref = useRefs(['container', 'index', 'zindex', 'nodes', 'tween'])
  const nodesRef = images.map(() => useRef(null))

  ref.index.current = 0
  ref.zindex.current = 0

  useMountEffect(() => setHover(true))

  const tweenNext = ({ pos, cached, index, zIndex }) => {
    const img = nodesRef?.[index].current

    gsap.killTweensOf(img)

    gsap
      .timeline()
      .add('enter')
      .set(img, { x: cached.x || pos.x, y: cached.y || pos.y, opacity: 1, scale: 1, zIndex }, 'enter')
      .add('entered')
      .to(img, { x: pos.x, y: pos.y, ...x.enter }, 'enter')
      .to(img, { opacity: 0, scale: 0.85, ...x.exit }, 'entered+=0.5')
  }

  const {
    hover: [, setHover],
  } = useMousePos([ref.container], {
    options: { lerp: 1, threshold: 200 },
    onDistance: ({ pos, mouse, cached }) => {
      tweenNext({ pos, mouse, cached, index: $(ref.index), zIndex: $(ref.zindex) })
      ref.index.current = ($(ref.index) + 1) % images?.length
      ref.zindex.current = ($(ref.zindex) + 1) % 500
    },
  })

  return (
    <div
      ref={ref.container}
      sx={{ variant: 'imagetrail', ...sx }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {children}
      {images.map(({ node: [Image, imgProps], aspectRatio, id }, i) => {
        const ratio = clamp(100 / aspectRatio, ...range)

        return (
          <div key={id} ref={nodesRef[i]} sx={{ variant: 'imagetrail.image', '--aspect-ratio': `${ratio}%` }}>
            <Image {...imgProps} />
          </div>
        )
      })}
    </div>
  )
}

export default ImageTrail
