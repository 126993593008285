import cls from 'classnames'
import Link from './Link'

const MultilineLink = ({ blok, children, className, lang, ...props }) => {
  const { link, text } = blok ?? {}

  return (
    <Link className={cls('link', className)} link={link} {...props}>
      {text}
    </Link>
  )
}
export default MultilineLink
