import { Flex } from 'theme-ui'
import { Section, Text, Button } from '@monobits/components'
import { Locomotion } from '@monobits/locomotive-scroll'
import SbEditable from 'storyblok-react'

import Link from './Link'
import Rte from './Rte'

const Sticky = ({ condition, children, id, ...props }) =>
  condition ? (
    <Locomotion {...props} sticky target={`[data-handle="${id}"]`.toString()}>
      {children}
    </Locomotion>
  ) : (
    children
  )

const ContentBlock = ({ blok, children, lang, parentId, ...props }) => {
  const { caption, content, cta_link, cta_text, cta_sticky, _uid } = blok ?? {}

  const sticky = { disabled: null, self: _uid, parent: parentId }[cta_sticky]

  return (
    <SbEditable content={blok} key={_uid}>
      <Section variant="border" sx={{ variant: 'blocks.content' }} data-handle={_uid} data-scroll data-fade {...props}>
        <span className="anchor" data-anchor={_uid} />
        <Text variant="caption" sx={{ variant: 'blocks.content.caption' }}>
          {caption}
        </Text>

        {cta_text && (
          <Sticky id={sticky} condition={!!sticky} offset={'-50%,75%'}>
            <Flex sx={{ variant: 'blocks.content.cta' }}>
              <Button size="lg" as={Link} link={cta_link}>
                {cta_text}
              </Button>
            </Flex>
          </Sticky>
        )}

        <Rte content={content} sx={{ variant: 'blocks.content.rte' }} />
      </Section>
    </SbEditable>
  )
}

export default ContentBlock
