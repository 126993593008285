import { gsap } from 'gsap'

export const wrapChange = (node, willChange = 'auto') => {
  const func = gsap.set
  const start = [node, { willChange }]
  const end = [node, { clearProps: 'will-change' }]

  return {
    onUpdate: func,
    onUpdateParams: start,
    onComplete: func,
    onCompleteParams: end,
    onReverseComplete: func,
    onReverseCompleteParams: end,
  }
}
