import Placeholder from './Placeholder'
import GlobalComponent from './GlobalComponent'

import * as X from '../components'
import { Field, FieldGroup, Select } from '../components/Form'

const Components = {
  global: GlobalComponent,
  'use-global': GlobalComponent,
  'nav-item': X.NavItem,
  'text-item': X.TextItem,
  'copyrights-mention': X.Copyrights,
  'email-link': X.Obfuscate.Email,
  'phone-link': X.Obfuscate.Phone,
  'multiline-link': X.MultilineLink,
  'bio-block': X.BioBlock,
  'legal-block': X.LegalBlock,
  'content-block': X.ContentBlock,
  'content-blocks': X.ContentBlocks,
  'contact-block': X.ContactBlock,
  'staff-list': X.StaffList,
  heading: X.Heading,
  navigation: X.Links,
  collapsibles: X.Collapsibles,
  rte: X.RteItem,
  form: X.Form,
  input: Field,
  textarea: Field,
  select: Select,
  'field-group': FieldGroup,
}

const DynamicComponent = ({ blok, source, ...props }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} {...props} />
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
