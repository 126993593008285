import { transitions, transparentize } from 'polished'
import { inset } from '@boiler/utils'

const activeLabel = {
  '--label-position': '0',
  '--label-scale': '1',

  opacity: '0',
}

const common = {
  // fontSize: 'inherit',

  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: 'text',
}

const focus = {
  // borderBottomColor: 'red',
  // boxShadow: (t) => `0 0 0 2px ${transparentize(0.85, t.rawColors.primary)}`,
  // outline: 'none',
}

const fieldStyles = {
  border: 'none',
  fontSize: 'inherit',
  // ...common,
  p: '0',
  // p: '0 0 var(--py) 0',
  borderRadius: 'var(--input-radius)',

  '&:focus': {
    ...focus,
  },
  '[data-value=true] &+label': activeLabel,
  '[data-upload=true] &+label': {
    opacity: '1',
  },

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&[type=number]': {
    MozAppearance: 'textfield',
  },

  '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
    display: 'flex',
    WebkitTextFillColor: (t) => t.colors.text,
  },
}

export default {
  __default: {
    position: 'relative',
    display: 'grid',
    gridRowGap: '3.5em',
    gridColumnGap: 1,

    '--input-radius': '0px',
    // '--px': '1em',
    // '--py': ['0.65em', null, '0.15em'],

    '& > .overlay': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      p: 1,
      textAlign: 'center',

      position: 'absolute',
      ...inset(0),
      zIndex: '1',

      visibility: 'hidden',
      transform: 'translateY(10px)',
      opacity: 0,
      ...transitions(['transform', 'opacity'], '0.5s ease 1s'),
    },
    '& > *:not(.overlay)': {
      ...transitions(['transform', 'opacity'], '0.5s ease 0.5s'),
    },

    '&[data-form-state] > .overlay': {
      visibility: 'visible',
      transform: 'translateY(0px)',
      opacity: 1,
    },

    '&[data-form-state] > *:not(.overlay)': {
      transform: 'translateY(5px)',
      opacity: 0,
    },
  },
  cell: {
    fontSize: 2,

    border: 'none',
    ...common,

    '--px': '1em',
    '--py': ['0.35em', null, '0.15em'],

    p: '0 0 var(--py) 0',

    '&[data-upload=true]': {
      display: 'grid',
      gridTemplateColumns: (t) => `1fr ${t?.sizes?.icon || '60px'}`,
      gridTemplateRows: '1fr',
      gridGap: 1,

      overflow: 'hidden',

      cursor: 'pointer',
      input: {
        cursor: 'pointer',
        position: 'absolute',
        textIndent: '200vw',
      },
      label: {
        position: 'relative',
        width: '100%',
        gridColumn: '1/2',
        overflow: 'hidden',

        '::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,

          background: (t) =>
            `linear-gradient(to right, ${transparentize(1, t.rawColors.background)}, ${t.colors.background})`,
          width: '1.5em',
        },
      },
    },

    '.icon': {
      gridRow: '1/2',
      gridColumn: '2/-1',
      display: 'flex',
    },
  },
  label: {
    '--label-padding': '3px',
    '--label-position': '-0%',
    '--label-scale': '1',

    fontSize: 2,

    width: 'fit-content',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    px: 0,

    transform: 'translateY(var(--label-position)) scale(var(--label-scale))',
    transformOrigin: 'left',
    transition: 'transform 0.15s ease',
  },
  input: {
    ...fieldStyles,
    gridColumn: '1/-1',
    gridRow: '1/-1',
  },
  autosubmit: {
    button: {
      fontSize: 'var(--size-small)',

      display: 'flex',
      justifyContent: 'flex-end',

      position: 'absolute',
      width: '3em',
      top: 0,
      bottom: 0,
      right: 'var(--px)',

      svg: {
        width: 'var(--icon-width)',
        maxWidth: 'var(--icon-width)',
      },
    },
  },
  select: {
    fontSize: 2,
    position: 'relative',
    border: 'none',
    ...common,

    '--py': ['0.35em', null, '0.15em'],
    pb: 'var(--py)',

    '&[aria-expanded=true] > aside': {
      pointerEvents: 'none',
      ...focus,
    },

    '&[data-value=true] label': activeLabel,

    '@media (hover: hover)': {
      cursor: 'pointer',
      select: {
        pointerEvents: 'none',
      },
    },

    svg: {
      height: 'auto',
      gridRow: '1/2',
      gridColumn: '2/-1',
    },

    '> aside': {
      ...fieldStyles,
      p: '0',
      position: 'relative',
      width: '100%',

      display: 'grid',
      gridTemplateColumns: (t) => `1fr ${t?.sizes?.icon || '60px'}`,
    },

    dropdown: {
      ...common,

      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'currentColor',

      ...focus,
      width: '100%',
      borderRadius: 'var(--input-radius)',
      bg: 'background',
      overflow: 'hidden',

      position: 'absolute',
      top: 'calc(100% - 1px)',
      left: 0,
      zIndex: '10',

      display: 'none',
      '@media (hover: hover)': {
        '[aria-expanded=true] &': { display: 'block' },
      },

      li: {
        // p: 'calc(var(--py) * 1.5) calc(var(--py) * 1.8) calc(var(--py) * 1.8) calc(var(--py) * 1.8)',
        p: '1em',

        variant: 'text.caption',

        cursor: 'pointer',

        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',

        '&:hover, &[data-selected=true]': {
          bg: (t) => transparentize(0.9, t.rawColors.primary),
        },
      },

      'li+li': {
        borderTopColor: 'primary',
      },
    },
  },
  textarea: {
    ...fieldStyles,
    height: '19rem',
    resize: 'none',
  },
}
