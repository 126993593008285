import { inset } from '@boiler/utils'

export default {
  __default: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',
    ...inset(0),
    zIndex: 1,
  },
  image: {
    fontSize: 3,
    width: '4em',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    pointerEvents: 'none',

    opacity: '0',

    transform: 'translate(-50%, -50%)',
    willChange: 'transform',

    '::after': {
      content: '""',
      pb: 'var(--aspect-ratio)',
      display: 'flex',
    },

    '.image': {
      position: 'absolute',
      ...inset(0),

      img: {
        objectFit: 'cover',
        objectPosition: 'top center',
      },
    },
  },
}
