import { Bloks } from '../bloks'

const Links = ({ blok, ...props }) => {
  const { body, _uid } = blok ?? {}
  const content = body?.length
    ? body.map((child) => <Bloks blok={child} key={child._uid} parentId={_uid} {...props} />)
    : null

  return <div sx={{ display: 'grid', gridGap: 'var(--nav-gap)' }}>{content}</div>
}

export default Links
