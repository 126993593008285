import { Text } from '@monobits/components'
import { Footer as Molecule } from '@boiler/molecules'
import SbEditable from 'storyblok-react'

import NewlineText from './NewlineText'
import { MapBloks } from '../bloks'
import { useData } from '../hooks'

import LogoLong from '../../assets/svg/logo-long.svg'

const Footer = (props) => {
  const { settings, lang } = useData()

  if (!settings) return null

  const { navigation, copyrights, location, contact_nav, socials, meta_description } = settings ?? {}

  return (
    <SbEditable content={settings} key={settings._uid}>
      <Molecule
        data-handle="footer"
        logo={LogoLong}
        homePath={lang.nullish ? '/' + lang.nullish : '/'}
        source={{
          navigation: '',
          description: '-desktop-only',
          socials: '',
          copyrights: '',
          address: '-desktop-only',
          contact: '-desktop-only',
        }}
        head={[
          <MapBloks items={navigation} />,
          <Text variant="caption" children={meta_description} sx={{ maxWidth: '40em' }} />,
          <MapBloks items={socials} />,
        ]}
        foot={[
          <MapBloks items={copyrights} rte={false} sx={{ whiteSpace: 'nowrap' }} />,
          <Text as="p" rte={false}>
            <NewlineText text={location} id={settings._uid} />
          </Text>,
          <MapBloks items={contact_nav} />,
        ]}
        {...props}
      />
    </SbEditable>
  )
}

export default Footer
