import { Section, Text } from '@monobits/components'
import SbEditable from 'storyblok-react'

import Rte from './Rte'

const LegalBlock = ({ blok, index, children, locales: { locales }, ...props }) => {
  const { caption, content, skip_index, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Section
        data-handle={_uid}
        variant="border"
        sx={{ variant: 'blocks.content', '&': { variant: 'layout.section.border.dense' } }}
        data-scroll
        data-fade
        {...props}
      >
        <Text variant="caption" sx={{ variant: 'blocks.content.caption' }}>
          {!skip_index && locales['article'] + '\u00A0' + (index || '') + '\u00A0—\u00A0'}
          {caption}
        </Text>
        <Rte content={content} variant="running" sx={{ variant: 'blocks.content.rte' }} />
      </Section>
    </SbEditable>
  )
}

export default LegalBlock
