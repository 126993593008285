module.exports = {
  homeSlug: 'home',
  includeLinks: true,
  resolveLinks: 'story',
  resolveRelations: ['use-global.reference', 'staff-list.items'],
  locales: {
    locales: ['locales'],
    members: ['members', 'member-titles', 'languages'],
  },
  langs: ['fr', 'en'],
}
