import PropTypes from 'prop-types'

import { forwardRef } from 'react'
import { Link } from 'gatsby'
import { Flex, Grid, useThemeUI } from 'theme-ui'
import { Icon } from '@monobits/components'

import { StyledHeader, StyledContainer } from './Header.styled'

/**
 * This is the Header component
 */
const Header = forwardRef(({ children, variant, sx, logo: Logo, homePath, sticky, shy, nav, ...props }, ref) => {
  const { useStickyHeader, useShyHeader } = useThemeUI(null).theme?.config

  return (
    <StyledHeader
      ref={ref}
      as="header"
      sx={{ variant: 'header.container', ...sx }}
      shy={shy ?? useShyHeader}
      sticky={sticky ?? useStickyHeader}
      {...props}
    >
      <StyledContainer>
        <Grid as="nav" {...nav} sx={{ variant: 'header.nav' }}>
          {children}
        </Grid>
      </StyledContainer>
    </StyledHeader>
  )
})

const LogoComponent = ({ children, logo: Logo, logoMobile: LogoMobile, homePath, sticky, shy, ...props }) => {
  const linked = homePath && { as: Link, to: homePath }
  return (
    <Flex {...linked} sx={{ variant: 'header.logo' }} aria-label="Logo" data-logos={!!LogoMobile} {...props}>
      <Icon className="-mouse" icon={Logo} variant="scale" />
      {LogoMobile && <Icon className="-touch" icon={LogoMobile} variant="scale" />}
    </Flex>
  )
}

Header.Logo = LogoComponent

Header.propTypes = {
  /** Content or components that will display in the navigation */
  children: PropTypes.any,
  /** Takes a SVGR Component */
  logo: PropTypes.func,
  /** The url path of the homepage. Allows for localized paths */
  homePath: PropTypes.string,
  /** Makes the header sticky */
  sticky: PropTypes.bool,
  /** Makes the header show/hide on scroll up/down */
  shy: PropTypes.bool,
  /** Add a margin as a placeholder for the height of the header */
  spacer: PropTypes.bool,
}

export default Header
