import { inset } from '@boiler/utils'

export default {
  __default: {
    '--height': 100,
    variant: 'layout.section.hero',

    position: 'relative',
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
    gridTemplateAreas: ['"image" "container"', null, '"image container"'],
    gridGap: 0,
  },
  container: {
    display: 'grid',
    gridArea: 'container',
    gridAutoRows: 'min-content',
    gridGap: 0,

    'ul + ul': { mt: 1 },
    '>ul .-rte + .-rte': { mt: '0.7em' },
  },
  nav: {
    justifyContent: 'space-between',
    '.close': {
      display: ['none', null, 'inline-block'],
      zIndex: 'nav',
    },
  },
  name: { mt: [1, null, 2], mb: 2 },
  content: {
    mt: 3,
    mb: 2,
    display: 'block',
  },
  image: {
    gridArea: 'image',

    width: '100%',
    height: ['auto', null, 'calc((var(--height, 100) * var(--unit)) - var(--offset, 0px))'],
    position: ['relative', null, 'absolute'],
    zIndex: '10',
    overflow: 'hidden',

    '.image': {
      width: '100%',
      position: ['relative', null, 'absolute!important'],
      ...inset(0),

      img: {
        objectFit: 'cover',
        objectPosition: 'top center',
      },
    },

    '--height': 100,
    '--unit': 'var(--vh-x, 1vh)',

    // Make sure is not sticky
    '@media (max-width: 61.95em)': { transform: 'unset!important' },

    '.close': {
      display: [null, null, 'none'],
      position: 'absolute',
      top: 1,
      right: 1,
      zIndex: 'nav',
    },
    '.social': {
      position: 'absolute',
      bottom: 1,
      left: 1,
      zIndex: 'nav',
    },
  },
}
