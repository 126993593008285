module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","titleTemplate":"%s | Laframboise Avocats","title":"Laframboise Avocats","copyrights":"2024 Tous droits réservés","openGraph":{"type":"website"}},
    },{
      plugin: require('../../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","custom":[{"name":["Suisse BP"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
