import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { locomotive } from '@monobits/styles'
import { inset } from '@monobits/core'
import { omit } from 'lodash'
import { easeInOut } from 'polished'

import config from './config'
import styles from './styles'
import colors from './colors'
import overrides from './overrides'
import transitions from './transitions'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import * as variants from './variants'

export default merge(omit(preset, ['colors']), {
  config,
  colors,
  transitions,

  breakpoints: ['43em', '62em', '82em'],

  styles: {
    root: {
      ...styles,
      ...locomotive,
      ...rootFontStyles,
      ...rootScaleStyles,

      '--stroke-absolute-value': (t) => t.borderWidths[1] + 'px',

      body: {
        bg: 'background',
        '.c-scrollbar': { zIndex: 'scrollbar' },
      },

      '&.has-scroll-scrolling [data-scroll-container]': {
        willChange: 'transform',
      },

      '&.has-scroll-smooth body': {
        position: 'fixed',
        ...inset(0),
      },

      '--inview-duration': '1.8s',
      '--inview-ease': 'cubic-bezier(0.16, 1, 0.3, 1)',
      '--inview-delay': '0.35s',
    },
  },

  motion: {
    collapsible: {
      transition: {
        duration: 0.75,
        ease: 'easeInOutCirc',
      },
      bezier: easeInOut('circ'),
    },
  },

  ...fonts,
  ...scales,
  ...overrides,
  ...variants,
})
