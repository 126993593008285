import { useState, useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { useMediaQuery, useConditionalEffect, useMountEffect } from '@react-hookz/web'
import { Section, Icon } from '@monobits/components'
import { useTimeline, useRefs } from '@monobits/gsap'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { ImageTrail, Slider } from '@boiler/components'
import { useLockBodyScroll } from 'react-use'

import CloudImage from './Cloudinary/CloudImage'
import ScrollTo from './ScrollTo'
import { useData } from '../hooks'

import LogoLong from '../../assets/svg/logo-long.svg'
import ArrowDown from '../../assets/svg/arrow-down-circle.svg'

const Intro = ({ anchor }) => {
  const { scroll } = useLocomotiveScroll()
  const {
    settings = {},
    rendered: [rendered, setRendered],
  } = useData()
  const { intro_images_array, intro_min_range, intro_max_range } = settings ?? {}

  const images = intro_images_array?.map(({ image, width, height, _uid }) => {
    return {
      id: _uid,
      node: [
        CloudImage,
        {
          url: image.filename,
          alt: image?.alt,
          options: { responsive: [300, [0, 800, 300]], placeholder: false },
          modifiers: (source, tr) => source.addTransformation(tr.optimize()).addTransformation(tr.optimizeScale(500)),
        },
      ],
      slide: [
        CloudImage,
        {
          url: image.filename,
          alt: image?.alt,
          options: { responsive: 300, placeholder: false },
          modifiers: (source, tr) => source.addTransformation(tr.autoWebp()).addTransformation(tr.optimizeScale(500)),
        },
      ],
      aspectRatio: width / height,
    }
  })

  let ref = useRefs(['button', 'slider'])
  let prepareCount = useRef(0)
  let tweenCount = useRef(0)

  const [ready, setReady] = useState(false)
  const isTouch = useMediaQuery('(hover: none)')

  const handleComplete = (bool) => {
    setReady(bool)
    setRendered(bool)
  }

  const [prepare, container] = useTimeline(({ $, node }) => {
    return gsap
      .timeline({ paused: true })
      .add('start')
      .set('#header', { opacity: 0, yPercent: -50 }, 'start')
      .set('#timestamp button', { scale: 0.25 }, 'start')
      .set('#slidernav, #slides, #scrolldown', { opacity: 0 }, 'start')
      .set(node, { pointerEvents: 'none' })
      .set($(element), { zIndex: 100, opacity: 0 })
  })
  const [timeline, element] = useTimeline(({ $, node }) => {
    const _container = gsap.getProperty($(container))
    const _element = gsap.getProperty(node)

    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 2, ease: 'expo.inOut' },
      onComplete: handleComplete,
      onCompleteParams: [true],
    })

    return () =>
      tl
        .add('start')
        .set(node, { y: _container('height') - _element('height') / 1.85 })
        .to(node, {
          y: _container('height') - _element('height'),
          opacity: 1,
          duration: 1.65,
          ease: 'circ.inOut',
          delay: 0.5,
        })
        .to(node, { y: 0, duration: 1.85, ease: 'expo.inOut' }, '+=0.65')
        .set(node, { clearProps: 'z-index' })
        .set($(container), { pointerEvents: 'auto' })
        .add('end', '-=1.4')
        .to('#slides', { opacity: 1 }, 'end-=0.35')
        .to('#scrolldown, #slidernav', { opacity: 1 }, 'end')
        .to('#header', { opacity: 1, yPercent: 0 }, 'end')
        .to('#timestamp button', { scale: 1 }, 'end+=0.25')
  })

  useMountEffect(() => !!rendered && gsap.set(container.current, { pointerEvents: 'auto' }))

  useConditionalEffect(
    () => {
      prepare.play()
      prepareCount.current = 1
    },
    undefined,
    [!rendered, !prepareCount.current, prepare]
  )

  useConditionalEffect(
    () => {
      setTimeout(() => timeline().timeScale(1.25).play(), 500)
      tweenCount.current = 1
    },
    [scroll, timeline, isTouch],
    [!rendered, !tweenCount.current, scroll, timeline, isTouch != null]
  )

  useConditionalEffect(
    () => {
      scroll.stop()
      console.log('stop')
    },
    [scroll],
    [scroll, !rendered]
  )
  useConditionalEffect(
    () => {
      scroll.start()
    },
    [scroll, ready],
    [!rendered, scroll, ready]
  )

  useLockBodyScroll(rendered ? false : !ready)

  if (!settings) return null

  return (
    <div ref={container.ref} sx={{ pointerEvents: 'none' }}>
      <Section
        variant="hero"
        sx={{
          '--mt': 'calc(var(--header-height) + 0.5em)',
          '--offset': 'calc(var(--mt) + (var(--padding-x) * 2))',
          mt: 'var(--mt)',

          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          gridTemplateAreas: '"logo" "content" "button"',
          gridGap: 1,
        }}
      >
        {isTouch === false && (
          <ImageTrail
            images={images}
            range={[intro_min_range.value, intro_max_range.value]}
            sx={{ gridArea: 'content', '@media (hover: hover)': { gridRow: '1/-1' } }}
          />
        )}

        <div ref={element.ref} sx={{ gridArea: 'logo', pointerEvents: 'none' }}>
          <Icon icon={LogoLong} sx={{ height: 'unset' }} />
        </div>

        {isTouch === true && (
          <Slider
            sx={{ gridArea: 'content' }}
            images={images}
            range={[intro_min_range.value, intro_max_range.value]}
            rendered={rendered}
          />
        )}

        <ScrollTo
          id="scrolldown"
          aria-label="scroll down"
          ref={ref.button}
          sx={{
            p: '1px',
            size: 'icon',
            position: 'relative',
            zIndex: 'nav',
            gridArea: 'button',
            justifySelf: 'end',
          }}
          anchor={anchor}
        >
          <Icon icon={ArrowDown} sx={{ '--stroke-absolute': 'var(--stroke-absolute-value)' }} />
        </ScrollTo>
      </Section>
    </div>
  )
}

export default Intro
