import { lazyload, placeholder } from '@cloudinary/react'
import { isEmpty } from 'lodash'
import { responsive } from './responsive'

export const getMedia = (obj, id) => {
  if (isEmpty(obj)) return {}

  const reducer = obj?.reduce((acc, { node }) => [...acc, node], [])
  const single = reducer?.filter((i) => i.public_id === id)?.[0] || {}

  const { width, height, resource_type, context } = single ?? {}

  return 'width' in single
    ? {
        aspectRatio: width / height,
        resource_type,
        context,
        width,
        height,
      }
    : {}
}

export const getCloudinaryProps = (url) => {
  const parts = url?.replace('https://res.cloudinary.com/', '')?.split('/')
  const file = parts.splice(0, 4)
  const name = parts.join('/').split('.')
  const ext = name[name.length - 1]

  return {
    id: file?.[3],
    type: file?.[1],
    filename: name.slice(0, -1).join('.'),
    ext,
  }
}

export const plugins = ({ lazyload: l = ['0px', 0.1], responsive: r = [400], placeholder: p = 'vectorize' }) => {
  const sets = {
    lazyload: !!l && lazyload(...l),
    responsive: !!r && responsive(...(Array.isArray(r) ? r : [r])),
    placeholder: !!p && placeholder(p),
  }
  return Object.values(sets).filter(Boolean)
}
