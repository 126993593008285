import { NestedMode } from '@monobits/color-transitions'
import { omit } from 'lodash'

const invertedTheme = (colors) => {
  const rest = omit(colors.modes, ['light', 'dark'])

  return {
    colors: {
      ...colors.modes?.dark,
      modes: {
        light: colors.modes?.dark,
        dark: colors.modes?.light,
        ...rest,
      },
    },
  }
}

// const InvertedMode = ({ children }) => children
const InvertedMode = (props) => <NestedMode modes={invertedTheme} {...props} />
export default InvertedMode
