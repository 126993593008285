const gaps = {
  head: ['2rem', 2],
  items: '0.6em',
  navigation: '1.65em',
  main: 2,
  secondary: 2,
  container: 1,
}

export default {
  __default: {
    visibility: 'hidden',
    clipPath: 'inset(0 0 0 var(--clip-path, 100%))',
    '--clip-path': '100%',
  },
  container: {
    bg: 'background',
    color: 'text',

    py: gaps.container,
    px: gaps.container,

    width: 'panel',
    zIndex: 'panel',

    gridGap: gaps.main,

    '--template-columns': '1fr',
    '--template-rows': 'auto 1fr auto',
  },
  head: {
    display: 'flex',
    justifyContent: ['space-between', null, 'unset'],

    '>aside, >a': {
      pr: 2,
      height: 'var(--header-height, 100px)',
      boxSizing: 'content-box',
    },

    '>div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: 'var(--panel-nav-width)',
    },
  },
  navigation: {
    alignSelf: 'start',
    height: 'fit-content',
    gridGap: gaps.navigation,
    '> a': { fontSize: [4, null, 2] },
  },
  secondary: {
    height: 'fit-content',
    gridGap: gaps.secondary,
    '>div': {
      alignSelf: 'end',
      gridGap: gaps.items,
    },

    '&[data-cta=true]': {
      gridTemplateColumns: '1fr auto',
      '>div': {
        gridColumn: '1/2',
      },
    },
  },
  body: {
    alignSelf: 'center',
  },
}
