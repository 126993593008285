import TextItem from './TextItem'

const Copyrights = ({ blok, ...props }) => {
  const { text } = blok ?? {}

  return (
    <TextItem {...props}>
      ©{new Date().getFullYear()} {text}
    </TextItem>
  )
}

export default Copyrights
