import SbEditable from 'storyblok-react'
import { Section, Text } from '@monobits/components'
import { localize } from '@boiler/utils'

import Rte from './Rte'

const BioBlock = ({ blok, children, locales: { members }, ...props }) => {
  const { caption, content, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Section
        variant="border"
        sx={{ variant: 'layout.section.border.small' }}
        data-handle={_uid}
        data-scroll
        data-fade
        {...props}
      >
        <Rte content={content} head={<Text variant="caption">{localize(caption, members)}</Text>} />
      </Section>
    </SbEditable>
  )
}

export default BioBlock
