import { Underlined } from '@monobits/components'
import { localize } from '@boiler/utils'

import parsePhoneNumber from 'libphonenumber-js'
import Obfuscate from 'react-obfuscate'

const parseNumber = (node) => parsePhoneNumber(node, 'CA')

const Bio = ({ name, master, locales }) => (
  <>
    {master && <span>{locales['me']}&nbsp;</span>}
    {name}
  </>
)

const Email = ({ email, title, ...props }) => (
  <Underlined {...props}>
    <Obfuscate email={email} headers={{ subject: `Contact | ${title}` }} target="_blank" />
  </Underlined>
)

const Phone = ({ phone, locales }) => {
  const { number, ext } = phone ?? {}
  const { telephone, poste } = locales ?? {}
  const parsed = parseNumber(number)

  return (
    <>
      {telephone}:&nbsp;
      <Underlined>
        <Obfuscate
          tel={`${parsed?.number}${ext ? `;${ext}` : ''}`}
          children={`${parsed.formatNational()}${ext ? ` ${poste} ${ext}` : ''}`}
        />
      </Underlined>
    </>
  )
}

const Fax = ({ fax, locales }) => (
  <>
    {locales['telecopieur']}:&nbsp;{parseNumber(fax).formatNational()}
  </>
)

const Admission = ({ year, locales }) => (
  <>
    {locales['admission-barreau']}&nbsp;{year}
  </>
)

const Languages = ({ languages, locales }) => (
  <>
    {locales['langue-s']}: {languages.map((i) => localize(i, locales)).join(', ')}
  </>
)

Bio.Name = Bio
Bio.Email = Email
Bio.Phone = Phone
Bio.Fax = Fax
Bio.Admission = Admission
Bio.Languages = Languages

export default Bio
