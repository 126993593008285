import { kebabCase } from 'lodash'

export const getLocales = async ({ SOURCES, ISO, Storyblok, setter }) => {
  // Locales Datasources
  const entries = Object.entries(SOURCES).reduce((acc, [, value]) => [...acc, ...value], [])

  const promises = []
  for (const datasource of entries) {
    await Storyblok.get(`cdn/datasource_entries`, {
      dimension: ISO,
      datasource,
    }).then(({ data }) => {
      const reduced = data.datasource_entries.reduce(
        (acc, node) => ({ ...acc, ...{ [kebabCase(node.name)]: node.dimension_value || node.value } }),
        {}
      )
      return promises.push({ [datasource]: reduced })
    })
  }

  Promise.all(promises).then((results) => {
    const refined = results.reduce((acc, obj) => ({ ...acc, ...obj }), {})
    const shape = Object.entries(SOURCES).reduce((acc, [key, keys]) => {
      const merged = keys?.reduce((acc, each) => ({ ...acc, ...(refined?.[each] ?? {}) }), {})
      return { ...acc, [key]: merged }
    }, {})

    return results.length && setter(shape)
  })
}

export const returnLocales = (locales, iso) =>
  locales &&
  Object.entries(locales).reduce(
    (acc, [key, value]) => ({ ...acc, ...{ [key]: iso in value ? value[iso] : value } }),
    []
  )
