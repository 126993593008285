export default {
  __default: {
    display: 'flex',
    flexDirection: 'column',

    fontSize: 2,

    '--gap-rte': 'var(--rte)',
    '--gap-li': ['1em', null, '0.75em'],

    '&>': { '* + *': { mt: 'var(--gap-rte)' } },

    'b, strong': {
      fontWeight: 600,
    },

    '.link': {
      textDecoration: 'underline',
      textDecorationSkipInk: 'none',
      textDecorationThickness: 1,
      textUnderlinePosition: 'under',
      textUnderlineOffset: 1,

      '@media (hover: hover)': {
        '&:hover': {
          textDecorationColor: 'transparent',
        },
      },
    },

    'ul, ol': {
      display: 'grid',
      fontSize: 'inherit',
      listStyle: 'revert',

      pl: 'var(--gap-li)',
      gridGap: 'var(--gap-li)',

      li: {
        pl: '0.2em',

        '> ul, > ol': { mt: 'var(--gap-li)' },
        '> p': {
          my: 'unset',
          fontSize: 'inherit',
          lineHeight: 1,
          display: 'inline-table',
        },
      },
    },
    'ol > li::marker': { fontSize: '0.7em' },
  },
}
