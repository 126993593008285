import { createElement } from 'react'
import { Box } from 'theme-ui'
import { multiLink } from '@boiler/utils'

import NewlineText from './NewlineText'

const Link = ({ children, link, forwardedAs, ...props }) => {
  let text
  if (Array.isArray(children)) text = children[0]

  return createElement(forwardedAs || Box, { ...multiLink(link), ...props }, <NewlineText text={text || children} />)
}
export default Link
