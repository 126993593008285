import PropTypes from 'prop-types'
import { props as _type } from '@monobits/types'

import { Box, Flex } from 'theme-ui'
import { useConditionalEffect } from '@react-hookz/web'
import { useRefs, useTimeline } from '@monobits/gsap'
import { useMousePos } from '@boiler/utils'

const KEYS = { VARIANT: 'staffItem' }

/**
 * This is the StaffItem component
 */
const StaffItem = ({ children, sx, image, ratio, ...props }) => {
  let refs = useRefs(['parent', 'stroke'])
  const { parent } = refs ?? {}

  const [timeline, element] = useTimeline(({ gsap, $, node }) =>
    gsap
      .timeline({
        paused: true,
        smoothChildTiming: true,
        defaults: { duration: 1, ease: 'power3.inOut' },
      })
      .add('start')
      .to(node?.childNodes, { autoAlpha: 1, scale: 1 }, 'start')
      .to($(refs.stroke), { autoAlpha: 1, scaleX: 0.999, duration: 0.65, ease: 'expo.inOut' }, 'start')
  )

  const {
    is,
    hover: [hover, setHover],
  } = useMousePos([parent, element], { options: { lerp: 0.5 } })

  useConditionalEffect(
    () => {
      if (hover) timeline.timeScale(1.2).play()
      if (!hover) timeline.timeScale(1).reverse()
    },
    [hover],
    [!is.touch, timeline]
  )

  return (
    <Box ref={refs.parent} sx={{ variant: KEYS.VARIANT, ...sx }} {...props}>
      <span className="text" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {children}
        <span ref={refs.stroke} className="stroke" />
        {!is.touch && (
          <div sx={{ variant: 'staffItem.hitbox' }}>
            <Flex
              ref={element.ref}
              as="aside"
              sx={{
                variant: 'staffItem.preview',
                '--aspect-ratio': ratio ? `${100 / ratio}%` : `${100 / (4 / 5)}%`,
              }}
            >
              <Flex className="box">{image}</Flex>
            </Flex>
          </div>
        )}
      </span>
    </Box>
  )
}

StaffItem.propTypes = {
  children: PropTypes.any.isRequired,
  ..._type.themeUiBase,
}

StaffItem.defaultProps = {
  as: 'button',
}

export default StaffItem
