import { Fragment, useRef, useState, useEffect, memo } from 'react'
import { Box, Flex, Grid } from 'theme-ui'
import { Icon } from '@monobits/components'

const KEYS = { VARIANT: 'staffCard' }

/**
 * This is the StaffCard component
 */
const StaffCard = ({ children, sx, image: [Image, imageProps], head, icon, link, hover, ...props }) => {
  const image = useRef()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const imgRef = image.current?.childNodes?.[0]?.childNodes?.[2]?.childNodes?.[2]
    if (imgRef?.complete) setLoaded(true)
  }, [])

  return (
    <Grid
      as="article"
      sx={{ variant: KEYS.VARIANT, ...sx }}
      data-hover
      data-scroll
      data-scroll-offset={'25%'}
      data-loaded={!!loaded}
      {...props}
    >
      <Box ref={image} sx={{ variant: 'staffCard.image' }} {...link}>
        <Image {...imageProps} onLoad={() => setLoaded(true)} />
      </Box>

      <Grid as="section" sx={{ variant: 'staffCard.content' }}>
        {head.length && (
          <Flex sx={{ variant: 'staffCard.head' }} {...link}>
            {head.map((item, i) => (
              <Fragment key={'this' + i}>{item}</Fragment>
            ))}
          </Flex>
        )}
        {children && (
          <Grid as="ul" sx={{ variant: 'staffCard.body' }}>
            {children}
          </Grid>
        )}
        {icon && (
          <Box sx={{ variant: 'staffCard.icon' }} {...link}>
            <Icon icon={icon} sx={{ '--stroke-absolute': 'var(--stroke-absolute-value)', p: '1px' }} />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default memo(StaffCard)
