import { Bloks } from '../bloks'

const ContentBlocks = ({ blok }) => {
  const { body, _uid } = blok ?? {}
  const content = body?.length ? body.map((child) => <Bloks blok={child} key={child._uid} parentId={_uid} />) : null

  return (
    <section sx={{ variant: 'layout.section.group' }} data-handle={_uid}>
      {content}
    </section>
  )
}

export default ContentBlocks
