import { Underlined } from '@monobits/components'
import { multiLink } from '@boiler/utils'

const NavItem = ({ blok, ...props }) => {
  const { link, text, locale } = blok

  return (
    <Underlined {...multiLink(link)} {...props} inverted>
      {text || locale}
    </Underlined>
  )
}

export default NavItem
