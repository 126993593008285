import { useState } from 'react'
import { Label, Input, Textarea } from 'theme-ui'
import { snakeCase } from 'lodash'
import { useDebouncedState } from '@react-hookz/web'
import { Icon } from '@monobits/components'

const types = {
  textarea: Textarea,
}

const Field = ({ name, type, handle, autosubmit, sx, onChange = () => {}, handler = () => {}, icon, ...props }) => {
  const id = handle || snakeCase(name)
  const [change, setChange] = useDebouncedState('', 20, 500)
  const [file, setFile] = useState(null)

  const Component = types[type] || Input
  const upload = type === 'file'

  return (
    <div
      data-value={!!change}
      data-upload={!!upload}
      sx={{
        position: 'relative',
        variant: autosubmit ? 'forms.autosubmit' : 'forms.cell',
        ...sx,
      }}
    >
      <Component
        id={id}
        name={id}
        type={type}
        sx={{ opacity: upload ? '0' : '1' }}
        onChange={(event) => {
          if (upload) setFile(event.target.files)
          setChange(event.target.value)
          onChange(event)
          handler(event)
        }}
        {...props}
      />
      <Label htmlFor={id}>{file ? file[0]?.name || name : name}</Label>

      {autosubmit || icon ? (
        <button className="icon" type={autosubmit ? 'submit' : null}>
          <Icon icon={icon} sx={{ '--stroke-absolute': 'var(--stroke-absolute-value)', p: '1px' }} />
        </button>
      ) : null}
    </div>
  )
}

export default Field
