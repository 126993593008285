import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'padding-x': ['20px', '40px'],
    'padding-m': ['60px', '90px'],
    'padding-l': ['80px', '248px'],
    'padding-xl': ['120px', '460px'],
    'header-height': ['30px', '40px', false],
  },
  ['375px', '2500px']
)

export const rootScaleStyles = {
  ...fluid.properties,
  '--stroke-x': '1px',
  '--stroke-s': '1px',
  '--stroke-m': '2px',
  '--main-gap': '1.25em',
  '--nav-gap': '0.65em',
  '--icon-width': '1.5em',
  '--panel-width': ['100vw', null, 'clamp(300px,35vw,500px)'],
  '--panel-nav-width': ['60vw', '20rem', 'clamp(300px,35vw,500px)'],
  '--panel-sizing': ['border-box', null, 'content-box'],
}

export default {
  space: fluid.scale,
  borderWidths: [0, 1, 2],
  sizes: {
    panel: 'var(--panel-width)',
    icon: '3.5rem',
    peek: '3.5em',
  },
  zIndices: {
    below: -1,
    default: 1,
    nav: 10,
    popup: 20,
    panel: 30,
    modal: 40,
    scrollbar: 50,
  },
}
