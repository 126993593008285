const degrees = ['0.001deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg', '360deg']

const anchor = {
  width: '100%',
  height: '1px',
  position: 'absolute',
  left: 0,
  pointerEvents: 'none',
}

export default {
  link: {
    underlined: {
      variant: 'text.small',

      display: 'inline-block',

      '--gap': '-0.18em',
      '--speed': '0.35s',
      '--from': '1',
      '--to': '1',

      '&.-inverted': {
        '--from': '1',
        '--to': '1',
        '.stroke': { opacity: 0 },
        '&._hover .stroke, &._active .stroke': {
          opacity: 1,
        },
      },

      '&._hover .stroke, &._active .stroke': {
        opacity: 0,
      },

      '.-rte &': {
        fontSize: 'inherit',
        textTransform: 'inherit',
      },
    },
  },
  colorswitch: {
    variants: {
      bullet: {
        '--size': '0.9em',
        willChange: 'transform',
      },
    },
    types: {
      inverted: {
        '--size': 'calc(0.9em + (var(--stroke-x) * 2))',
        border: 'var(--stroke-x) solid var(--bg)',
        bg: 'var(--color)',
      },
    },
  },
  button: {
    styles: {
      fontSize: 1,
      textTransform: 'uppercase',
      '&::before': {
        borderRadius: '50%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'var(--border-color)',
      },
      '&._hover::before': {
        transform: 'scale(1.05)',
      },
    },
    variants: {
      primary: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.primary,
        '--border-color': (t) => t.colors.primary,
      },
      inverted: {
        '--text': (t) => t.colors.text,
        '--bg': (t) => t.colors.background,
        '--text-hover': 'var(--text)',
        '--bg-hover': 'var(--bg)',
        '--border-color': 'var(--bg)',
      },
      stroke: {
        '--text': (t) => t.colors.primary,
        '--bg': 'transparent',
        '--text-hover': 'var(--text)',
        '--bg-hover': 'var(--bg)',
        '--border-color': 'var(--text)',
        '--scale': '1',

        '&._hover::before': {
          transform: 'scale(var(--scale))',
        },
      },
    },
    sizes: {
      sm: { size: '4em', p: '0.5em' },
      md: { size: '6em', p: '0.5em' },
      lg: { size: '8em', p: '0.65em' },
      __default: { variant: 'button.sizes.md' },
    },
  },
  layout: {
    section: {
      heading: {
        my: 3,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',

        '>*': {
          width: '100%',
        },

        '.anchor': {
          ...anchor,
          top: 'calc(var(--padding-l) * -1)',
        },
      },
      content: {
        my: 4,
        display: 'flex',
        flexDirection: 'column',

        '.anchor': {
          ...anchor,
          top: 'calc(var(--padding-xl) * -1)',
        },
      },
      cards: {
        my: 3,
        display: 'grid',

        '--template-columns': ['1fr', 'repeat(auto-fill, minmax(28em, 1fr))'],
        '@media (min-width: 1600px)': { '--template-columns': 'repeat(3, 1fr)' },

        gridTemplateColumns: 'var(--template-columns)',

        gridColumnGap: 1,
        gridRowGap: 2,
      },
      border: {
        mb: 4,
        '& + &': { mt: 3 },

        small: { mb: 0, '& + &': { mt: 3 } },
        dense: { mb: 0, '& + &': { mt: 2 } },

        '--padding': '3em',

        pt: 'var(--padding)',
        borderTopWidth: 1,
        borderTopColor: 'text',
        borderTopStyle: 'solid',

        '.anchor': {
          ...anchor,
          top: 'calc(var(--padding-l) * -1)',
        },
      },
    },
  },
  list: {
    styles: {
      variant: 'text.subtitle',
      borderWidth: 1,
      borderColor: 'text',
      borderStyle: 'solid',
      borderLeft: 'none',
      borderRight: 'none',
      '& + &': {
        borderTop: 'none',
      },
    },
    __default: {
      variant: 'list.styles',

      width: '100%',
      py: '1em',
    },
    collapsible: {
      variant: 'list.styles',

      '::before,::after': {
        display: 'none',
      },

      head: {
        width: '100%',
        position: 'relative',

        '--icon-size': ['1.2em', null, '0.9em'],

        gridTemplateColumns: '1fr var(--icon-size)',
        gridColumnGap: 1,
        gridRowGap: 0,
      },

      text: {
        width: '100%',
        py: '1em',
        gridColumn: '1/2',
      },
    },
    icon: {
      position: 'absolute',
      gridColumn: '2/3',
      size: '100%',

      alignItems: 'center',

      aside: {
        position: 'absolute',
        width: '100%',

        borderRadius: '50%',
        borderWidth: 1,
        borderStyle: 'solid',

        '--rotation': '0.001deg',

        '[data-selected=true] &': {
          '--rotation': degrees[2],
        },
        '[aria-expanded=true] &': {
          '--rotation': degrees[5],
        },

        transform: 'rotate(var(--rotation))',
        transition: 'transform var(--speed) ease',

        svg: {
          '--stroke-absolute': (t) => t.borderWidths[1] + 'px',
        },
      },
    },
  },
}
