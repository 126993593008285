export default {
  __default: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'flex-start',
    fontSize: 1,
    pl: '1.5em',

    '&>div': {
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
    },
  },
}
